<template>
  <v-card elevation="2">
    <v-breadcrumbs :items="items" divider="/" />
  </v-card>
</template>

<script>
export default {
  name: "pageNavigation",
  props: { items: Array },
};
</script>

<style>
</style>